import React from "react";

import AllPagesPDFViewer from "../components/pdf/all-pages";

import './Funktionsweise.css'

/* This is required only if the project file is located 
inside the app. Otherwise you can use the external link of the pdf file*/
import samplePDF from "../components/pdf/eshlFunktionsweise.pdf";

export default function App() {
  return (
      <div className="all-page-container">
        <AllPagesPDFViewer pdf={samplePDF} />
      </div>
  );
}