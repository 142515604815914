import React, { Component } from "react";
import Plot from "react-plotly.js";

class BudgetPlotLive extends Component {
    constructor(props) {
        super(props);
        this.state = {
            jsondatay: null,
            jsondatax: null,
            loading: true,
            datamissing: false,
            cachedWidth: window.outerWidth,
            co2limit: null,
            co2diff: null,
            co2emitted: null,
            co2el: null,
            co2th: null,
            budgetusedpercentage: null,
            date: this.props.date,
            starttime: null,
            endtime: null,
            budgetupdated: false,
        };
    }

    handleResize = (e) => {
        if (this.state.chachedWidth !== window.outerWidth) {
            window.location.reload(false); //deprecated; Der Browser wird zu einem reload gezwungen, da sich der Plot ansonsten nicht immer wieder an die Größe des viewports anpasst.
        }
        this.setState({ chachedWidth: window.outerWidth });
    };

    async componentDidMount() {
        window.addEventListener("resize", this.handleResize); // Immer wenn die Größe des viewports geändert wird, wird handleResize() aufgerufen
        await this.getBudgets();
        // this.fetchData();
        // setTimeout(this.fetchData, 1000);
        setTimeout(
            function () {
                this.fetchData();
            }
                .bind(this),
            1000
        );
        this.myinterval = setInterval(() => {
            this.getBudgets();
            this.fetchData();
        }, 900000); // Der Plot wird alle 900s (15min) aktualisiert
    }

    // componentDidUpdate(prevProps) {
    //     if (this.props.starttime !== prevProps.starttime) {
    //         this.fetchData();
    //     }
    // }

    async getBudgets() {
        fetch(
            `https://smarthome.iip.kit.edu/api/api/getBudget/${this.state.date.getFullYear()}-${this.state.date.getMonth() + 1
            }-${this.state.date.getDate()}/`,
            {
                method: "GET",
                credentials: "include",
            }
        )
            .then((response) => {
                if (response.ok) {
                    return response.json();
                } else {
                    return Promise.reject(response.status);
                }
            })
            .then((data) => {
                this.setState({ starttime: 200 });
                let result = [];
                if (data.length > 0) {
                    if (
                        data[0].start_timestamp !== null &&
                        data[0].end_timestamp !== null &&
                        data[0].start_date !== null &&
                        data[0].end_date !== null &&
                        data[0].budget !== null &&
                        data[0].modus !== null
                    ) {
                        let number = 1;
                        for (const element of data) {
                            console.log(element.start_timestamp)
                            this.setState({ starttime: element.start_timestamp });
                            this.setState({ endtime: element.end_timestamp });
                            this.setState({ start_date: new Date(element.start_date).toISOString().split('T')[0] });
                            this.setState({ end_date: new Date(element.end_date).toISOString().split('T')[0] });
                            this.setState({ co2limit: element.budget });
                            this.setState({ budgetupdated: true })
                            result.push({
                                number: number,
                                start: element.start_timestamp,
                                end: element.end_timestamp,
                                start_date: new Date(element.start_date).toISOString().split('T')[0],
                                end_date: new Date(element.end_date).toISOString().split('T')[0],
                                budget: element.budget,
                                modus: element.modus,
                            });
                            number = number + 1;
                        }
                    }
                    // this.setState({ starttime: 100 });
                    // this.setState({ endtime: result["end"] });
                    // this.setState({ co2limit: result.budget });
                    console.log("first.")
                } else {
                    console.log("No Budget found.")
                }
                //     if (
                //       data[0].storyline_multiplikator !== null &&
                //       data[0].storyline_name !== null
                //     ) {
                //       setMultiplikator(data[0].storyline_multiplikator);
                //       setStorylineName(data[0].storyline_name);
                //     } else {
                //       setMultiplikator(1);
                //       setStorylineName("");
                //     }
                //     if (data[0].reward_points !== null) {
                //       setPoints(data[0].reward_points);
                //     } else {
                //       setPoints(0);
                //     }
                //     setGeschafft(`${data[0].geschafft}`.toLowerCase());
                //   } else {
                //     setBudgetEnabled(false);
                //     setStorylineEnabled(false);
                //     setPointsEnabled(false);
                //     setGeschafft("null");
                //   }
                //   setBudgetValues(result);
            })
            // .then(this.fetchData())
            .catch((error) => console.log(error));


    }

    fetchData() {
        const requestOptions = {
            method: "GET",
            credentials: "include",
        };

        fetch(
            "https://smarthome.iip.kit.edu/api/api/getco2consumption/" +
            this.state.starttime +
            "/" +
            this.state.endtime +
            "/",
            requestOptions
        )
            .then((response) => {
                if (response.ok) {
                    this.setState({
                        datamissing: false,
                    });
                    console.log("second.")
                    return response.json().then((responsedata) =>
                        this.setState({
                            //co2diff: this.state.co2limit - responsedata.co2 > 0 ? this.state.co2limit - responsedata.co2 : 0,
                            co2diff: this.state.co2limit - (responsedata.co2_el + responsedata.co2_th) > 0 ? this.state.co2limit - (responsedata.co2_el + responsedata.co2_th) : 0,
                            //co2emitted: responsedata.co2,
                            co2emitted: responsedata.co2_el + responsedata.co2_th,
                            co2el: responsedata.co2_el,
                            //co2th: responsedata.co2_th,
                            co2th: responsedata.co2_th,
                            //budgetusedpercentage: responsedata.co2 / this.state.co2limit * 100,
                            budgetusedpercentage: (responsedata.co2_el + responsedata.co2_th) / this.state.co2limit * 100,
                            //jsondatay: [responsedata.co2, this.state.co2limit - responsedata.co2],
                            jsondatay: [(responsedata.co2_el + responsedata.co2_th), this.state.co2limit - (responsedata.co2_el + responsedata.co2_th)],
                            jsondatax: [1],
                            loading: false,
                        })
                    );
                } else if (response.status === 404) {
                    console.log("second.")
                    this.setState({
                        datamissing: true,
                    });
                    return Promise.reject("error 404");
                } else {
                    this.setState({
                        datamissing: true,
                    });
                    return Promise.reject("some other error" + response.status)
                }

            })
            .catch((error) => {
                console.log(error);
            });
    }

    render() {
        if (this.state.datamissing === true) {
            return (
                <div>
                    <p>Error: Data not found</p>
                </div>
            );
        } else if (this.state.loading === true) {
            return (
                <div>
                    <p>Loading...</p>
                </div>
            );
        } else {
            return (
                <div
                    // style={{
                    //     width: window.innerWidth > 1081 ? "1080px" : window.innerWidth,
                    // }}
                    style={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                        width: "100%",
                        // height: "100%",
                        margin: "0 auto",
                    }}

                >
                    <div
                        style={{
                            alignItems: "center",
                            // display: "flex",
                            width: "100%",
                            // justifyContent: "space-evenly",
                        }}
                    >
                        <p>
                            CO2-Budget: {this.state.co2limit} kg
                        </p>
                        <p>
                            {this.state.start_date} bis {this.state.end_date}
                        </p>
                    </div>

                    <Plot
                        style={{ width: "100%", height: "100%" }}
                        data={[
                            // {
                            //     type: "bar",
                            //     x: this.state.jsondatax,
                            //     y: [this.state.co2emitted],
                            //     marker: {
                            //         color: 'red',
                            //     },
                            //     name: 'Verbraucht',
                            // },
                            {
                                type: "bar",
                                x: this.state.jsondatax,
                                y: [this.state.co2th],
                                marker: {
                                    color: 'rgb(153,0,0)',
                                },
                                name: 'Wärme',
                            },
                            {
                                type: "bar",
                                x: this.state.jsondatax,
                                y: [this.state.co2el],
                                marker: {
                                    color: 'rgb(255,103,103)',
                                },
                                name: 'Strom',
                            },
                            {
                                type: "bar",
                                x: this.state.jsondatax,
                                y: [this.state.co2diff],
                                marker: {
                                    color: 'rgb(153,201,69',
                                },
                                name: 'Übrig',
                            }
                        ]}
                        layout={{
                            barmode: "stack",
                            showlegend: true,
                            autosize: true,
                            margin: {
                                r: 0,
                                t: 0,
                                pad: 5,
                            },
                            // title: "CO2-Budget",
                            // titlefont: { size: 30 },
                            legend: {
                                font: { size: 16 },
                                x: 2,
                            },
                            xaxis: {
                                tickvals: [1],
                                tickmode: "array",
                                ticktext: [""],
                                tickfont: { size: 16 },
                            },
                            yaxis: {
                                title: "kg CO2",
                                titlefont: { size: 16 },
                                tickfont: { size: 16 },
                                gridcolor: "black"
                            },
                            plot_bgcolor: "rgba(0, 0, 0, 0)",
                            paper_bgcolor: "rgba(0, 0, 0, 0)",
                            font: {
                                color: "black",
                            },

                        }}
                        config={{
                            displayModeBar: false,

                        }}

                        useResizeHandler
                    ></Plot>
                </div>
            );
        }

    }
}

export default BudgetPlotLive;