import React from "react";
import Anreiz from '../components/Anreize/Anreize.js';
import Hintergruende from '../components/Anreize/Hintergruende.js';
import Tipps from '../components/Anreize/Tipps.js';
import Profil from "../components/Profil/Profil.js";
import './HeutigerAnreiz.css'

export default function HeutigerAnreiz () {

    return(
        <div className="anreizContainer"> 
            <div className="anreizContent">
                <Anreiz id="anreize"/>
                <Hintergruende/>
                <Tipps/>
            </div>
            <div className="anreizContent">
                <Profil></Profil>
            </div>
        </div>
    ) 
}