import React, {useEffect, useState} from 'react';
import { Button, DateRangePicker, InputNumber, SelectPicker } from 'rsuite';
import { startOfDay, endOfDay } from 'date-fns';

export default function Phases(props) {

    const [phaseValues, setPhaseValues] = useState([])

    useEffect(() => {
        setPhaseValues(props.phaseValues)
    }, [props.phaseValues])

    function addPhase() {
        setPhaseValues(
            [...phaseValues, 
            {
                number: phaseValues.length + 1,
                start: startOfDay(new Date(Date.parse(props.date))) * 1,
                end: endOfDay(new Date(Date.parse(props.date))) * 1,
                mode: 'max',
                limit: 0
            }]
        )
        props.onChange(
            [...phaseValues,
            {
                number: phaseValues.length + 1,
                start: startOfDay(new Date(Date.parse(props.date))) * 1,
                end: endOfDay(new Date(Date.parse(props.date))) * 1,
                mode: 'max',
                limit: 0
            }]
        )
    }

    function deletePhase() {
        setPhaseValues([...phaseValues].slice(0, -1))
        props.onChange([...phaseValues].slice(0, -1))
    }

    function drawPhases() {
        const phaseComponents = []
        if (phaseValues?.length > 0) {
            for (const phaseValue of phaseValues) {
                phaseComponents.push(
                    <div style={{display: 'flex', flexDirection: 'column', marginBottom: '2%'}} key={phaseValue.number}>
                        <div style={{display: 'flex', justifyContent: 'space-evenly', alignItems: 'center'}}>
                            <span>
                                Phase {phaseValue.number}
                            </span>
                            <DateRangePicker
                                format="HH:mm"
                                placement="auto"
                                onOk={value => {
                                    const found = phaseValues?.find(test => test.number === phaseValue.number) 
                                    found.start = value[0] * 1
                                    found.end = value[1] * 1
                                    setPhaseValues([...phaseValues])
                                    props.onChange([...phaseValues])
                                }}
                                ranges={[{
                                    label: 'Heute',
                                    value: [startOfDay(new Date(Date.parse(props.date))), endOfDay(new Date(Date.parse(props.date)))]
                                }]}
                                value={[new Date(phaseValue.start), new Date(phaseValue.end)]}
                                cleanable={false}
                            />
                            <SelectPicker
                                data={[{"label": "Maximalwert", "value": "max"}, {"label": "Durchschnitt", "value": "mean"}]}
                                value={phaseValue.mode}
                                cleanable={false}
                                searchable={false}
                                onChange={value => {
                                    const found = phaseValues?.find(test => test.number === phaseValue.number) 
                                    found.mode = value
                                    setPhaseValues([...phaseValues])
                                    props.onChange([...phaseValues])
                                }}
                            />
                            <div style={{width: '130px'}}>
                                <InputNumber
                                    postfix="Watt"
                                    defaultValue={0}
                                    step={10}
                                    min={0}
                                    onChange={value => {
                                        const found = phaseValues?.find(test => test.number === phaseValue.number) 
                                        found.limit = value
                                        setPhaseValues([...phaseValues])
                                        props.onChange([...phaseValues])
                                    }}
                                    value={phaseValue.limit}
                                />
                            </div>
                        </div>
                    </div>
                )
            }
        }
        
        return phaseComponents
    }

    return(
        <>
            <div style={{width: '100%', display: 'flex', justifyContent: 'space-evenly', marginBottom: '2%'}}>
                <Button
                    onClick={addPhase}
                    style={{width: '10%'}}
                    appearance="primary"
                >
                    +
                </Button>
                <Button
                    onClick={deletePhase}
                    disabled={phaseValues?.length === 0}
                    style={{width: '10%'}}
                    appearance="primary"
                >
                    -
                </Button>
            </div>
            <div>
                {drawPhases()}   
            </div>
        </>
    )
}