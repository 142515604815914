import { React, useEffect, useState } from "react";
import { Modal } from "rsuite";
import * as MdIcons from "react-icons/md";

export default function Errungenschaften() {
  const [modalOpen, setModalOpen] = useState(false);
  const [pv_erzeugung_genutzt, set_pv_erzeugung_genutzt] = useState("false");
  const [spuelmaschine_nachts, set_spuelmaschine_nachts] = useState("false");
  const [waschmaschine_nachts, set_waschmaschine_nachts] = useState("false");

  useEffect(() => {
    fetch(
      `https://smarthome.iip.kit.edu/api/api/getErrungenschaftenFortschritt/`,
      {
        method: "GET",
        credentials: "include",
      }
    )
      .then((response) => {
        if (response.ok) {
          return response.json();
        } else {
          return Promise.reject(response.status);
        }
      })
      .then((data) => {
        set_pv_erzeugung_genutzt(data[0].pv_erzeugung_genutzt);
        set_spuelmaschine_nachts(data[0].spuelmaschine_nachts);
        set_waschmaschine_nachts(data[0].waschmaschine_nachts);
      })
      .catch((error) => console.log(error));
  }, []);

  function handleOpen() {
    setModalOpen(true);
  }

  function handleClose() {
    setModalOpen(false);
  }

  return (
    <>
      <button
        className="btn"
        onClick={() => {
          handleOpen();
        }}
      >
        Errungenschaften
      </button>
      <Modal
        open={modalOpen}
        onClose={handleClose}
        size={window.innerWidth < 1080 ? "" : "full"}
      >
        <div
          style={{
            width: "100%",
            display: "flex",
            flexDirection: "column",
            alignContent: "center",
            fontSize: "150%",
          }}
        >
          <div
            style={{ display: "flex", margin: "1% auto", textAlign: "center" }}
          >
            {pv_erzeugung_genutzt === "true" ? (
              <MdIcons.MdOutlineDone
                style={{ alignSelf: "center", color: "green" }}
              />
            ) : (
              <MdIcons.MdClose style={{ alignSelf: "center", color: "red" }} />
            )}
            Verbrauche an einem Tag nur so viel, wie die PV-Anlage des ESHL
            produziert.
          </div>
          <div
            style={{ display: "flex", margin: "1% auto", textAlign: "center" }}
          >
            {spuelmaschine_nachts === "true" ? (
              <MdIcons.MdOutlineDone
                style={{ alignSelf: "center", color: "green" }}
              />
            ) : (
              <MdIcons.MdClose style={{ alignSelf: "center", color: "red" }} />
            )}
            Lasse die Spühlmaschine zwischen 00:00 Uhr und 06:00 Uhr laufen.
          </div>
          <div
            style={{ display: "flex", margin: "1% auto", textAlign: "center" }}
          >
            {waschmaschine_nachts === "true" ? (
              <MdIcons.MdOutlineDone
                style={{ alignSelf: "center", color: "green" }}
              />
            ) : (
              <MdIcons.MdClose style={{ alignSelf: "center", color: "red" }} />
            )}
            Lasse die Waschmaschine zwischen 00:00 Uhr und 06:00 Uhr laufen.
          </div>
        </div>
      </Modal>
    </>
  );
}
