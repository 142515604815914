import { React, useEffect, useState } from "react";
import { Modal } from "rsuite";
import "./Rangliste.css";

export default function Rangliste() {
  const [modalOpen, setModalOpen] = useState(false);
  const [ranglisteContent, setRanglisteContent] = useState([]);

  useEffect(() => {
    fetch(`https://smarthome.iip.kit.edu/api/api/getRangliste/`, {
      method: "GET",
      credentials: "include",
    })
      .then((response) => {
        if (response.ok) {
          return response.json();
        } else {
          return Promise.reject(response.status);
        }
      })
      .then((data) => {
        setRanglisteContent(data);
      })
      .catch((error) => console.log(error));
  }, []);

  function handleOpen() {
    setModalOpen(true);
  }

  function handleClose() {
    setModalOpen(false);
  }

  return (
    <>
      <button
        className="btn"
        onClick={() => {
          handleOpen();
        }}
      >
        Rangliste
      </button>
      <Modal
        open={modalOpen}
        onClose={handleClose}
        size={window.innerWidth < 1080 ? "" : "full"}
      >
        <div style={{ overflowX: "auto" }}>
          <table
            style={{
              width: "100%",
              textAlign: "center",
              fontSize: "150%",
              borderCollapse: "separate",
              borderSpacing: "25px 0",
            }}
            onClick={handleClose}
          >
            <thead>
              <tr>
                <th className="dontShowOnMobile"></th>
                <th style={{ width: "16%" }}>Name</th>
                <th style={{ width: "16%" }}>Punkte</th>
                <th style={{ width: "16%" }}>absolvierte Anreize</th>
                <th style={{ width: "16%" }}>Herausforderungen</th>
                <th style={{ width: "16%" }}>Längste Streak</th>
                <th style={{ width: "16%" }}>Zeitraum</th>
              </tr>
            </thead>
            <tbody>
              {ranglisteContent.map((element) => (
                <tr key={element.name}>
                  <td
                    style={{ padding: "0.5% 0", justifyContent: "center" }}
                    className="dontShowOnMobile"
                  >
                    <form className="form">
                      <label className="custom-file-upload-rangliste fas label">
                        <div className="img-wrap">
                          <img
                            className="img"
                            id="profilePicture"
                            src={`https://smarthome.iip.kit.edu/api/api/getProfilBild/${element.startts
                              }/?ts=${Date.now()}`}
                            alt=""
                          />
                        </div>
                      </label>
                    </form>
                  </td>
                  <td>{element.name}</td>
                  <td>{element.punkte}</td>
                  <td>{element.absolvierteAnreize}</td>
                  <td>{element.herausforderungen}</td>
                  <td>{element.longest_streak}</td>
                  <td>
                    {new Date(element.startts).toLocaleDateString()} -{" "}
                    {new Date(element.endts).toLocaleDateString()}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </Modal>
    </>
  );
}
