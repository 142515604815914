import React from 'react';
import './Livedaten.css';
import LinePlotLive from '../components/Plot/LinePlotLive';
import BarPlotLive from '../components/Plot/BarPlotLive';

//Komponent, der die Livedaten anzeigt

class Livedaten extends React.Component {

    constructor(props){
      super(props);
      this.state = {  
        spalte: 'Leistung_Gesamt',
        anzeige: 'plot'
      }
      this.handleChange = this.handleChange.bind(this);
      this.onChangeValue = this.onChangeValue.bind(this);
  }

  handleChange(e) {         // Funktion, die auf eine Auswahl in <select> reagiert und den State entsprechend setzt, welcher Anschluss des ESHL im LinePlot visualisiert werden soll.
    this.setState({
      spalte: e.target.value
    })
  }

  onChangeValue(event) {          // Funktion, die auf eine Auswahl in <form> reagiert und den state entsprechend zu plot="LinePlotLive" oder bar="BarPlotLive" setzt.
    this.setState({
      anzeige: event.target.value
    })
  }

  
  render() {
    if (this.state.anzeige === 'plot'){
    return (
        <div  className="parent">
          <form className="liveForm" onChange={this.onChangeValue}>
            <input id="btn1" type='radio' name='auswahl' value='plot' defaultChecked/><label className='labelClass' htmlFor="btn1"> Plot (sekündlich live) </label>
            <input id="btn2" type='radio' name='auswahl' value='bar'/><label className='labelClass' htmlFor="btn2"> Balkendiagramm (live) </label>
          </form>
              <select value={this.state.spalte} onChange={this.handleChange}>
                <option value='Leistung_Gesamt'>Leistung Gesamt</option>
                <option value='2fach_Dose_Eingang'>2fach_Dose_Eingang</option>
                <option value='2fach_Dose_Herd_rechts'>2fach_Dose_Herd_rechts</option>
                <option value='2fach_Dose_Kuechenfenster_links'>2fach_Dose_Kuechenfenster_links</option>
                <option value='2fach_Dose_Kuechenfenster_rechts'>2fach_Dose_Kuechenfenster_rechts</option>
                <option value='2fach_Dose_Tuer_Schlafen1'>2fach_Dose_Tuer_Schlafen1</option>
                <option value='2fach_Dose_Tuer_Schlafen2'>2fach_Dose_Tuer_Schlafen2</option>
                <option value='3fach_Dose_Bad'>3fach_Dose_Bad</option>
                <option value='3fach_Dose_Kueche_links'>3fach_Dose_Kueche_links</option>
                <option value='3fach_Dose_Kueche_rechts'>3fach_Dose_Kueche_rechts</option>
                <option value='4fach_Dose_TV_1'>4fach_Dose_TV_1</option>
                <option value='4fach_Dose_TV_2'>4fach_Dose_TV_2</option>
                <option value='5fach_Dose_Schlafen1_1'>5fach_Dose_Schlafen1_1</option>
                <option value='5fach_Dose_Schlafen1_2'>5fach_Dose_Schlafen1_2</option>
                <option value='5fach_Dose_Schlafen2_1'>5fach_Dose_Schlafen2_1</option>
                <option value='5fach_Dose_Schlafen2_2'>5fach_Dose_Schlafen2_2</option>
                <option value='Backofen'>Backofen</option>
                <option value='Dunstabzugshaube'>Dunstabzugshaube</option>
                <option value='Gefrierschrank'>Gefrierschrank</option>
                <option value='Kaffeemaschine'>Kaffeemaschine</option>
                <option value='Klimaanlage'>Klimaanlage</option>
                <option value='Kochfeld1'>Kochfeld1</option>
                <option value='Kochfeld2'>Kochfeld2</option>
                <option value='Kochfeld3'>Kochfeld3</option>
                <option value='Kuehlschrank'>Kuehlschrank</option>
                {/* <option value='LEER1'>LEER1</option>
                <option value='LEER2'>LEER2</option>
                <option value='LEER3'>LEER3</option>
                <option value='LEER4'>LEER4</option>
                <option value='LEER5'>LEER5</option>
                <option value='LEER6'>LEER6</option> */}
                <option value='Licht_Bad'>Licht_Bad</option>
                <option value='Licht_Hauptraum'>Licht_Hauptraum</option>
                <option value='Licht_Schlafen1'>Licht_Schlafen1</option>
                <option value='Licht_Schlafen2'>Licht_Schlafen2</option>
                <option value='Spuelmaschine'>Spuelmaschine</option>
                <option value='Trockner'>Trockner</option>
                <option value='Tuer_zu_Schlafen1'>Tuer_zu_Schlafen1</option>
                <option value='Tuer_zu_Schlafen2'>Tuer_zu_Schlafen2</option>
                <option value='Waschmaschine'>Waschmaschine</option>
                <option value='PVGes'>PV-Anlagen</option>
              </select>
          <div>
            <LinePlotLive spalte = {this.state.spalte}/>
          </div>
        </div>
        
      )
    }
    else if (this.state.anzeige === 'bar'){
      return(
        <div className="parent">
          <form className="liveForm" onChange={this.onChangeValue}>
            <input id="btn3" type='radio' name='auswahl' value='plot'/><label className='labelClass' htmlFor="btn3"> Plot (sekündlich live) </label>
            <input id="btn4" type='radio' name='auswahl' value='bar' defaultChecked/><label className='labelClass' htmlFor="btn4"> Balkendiagramm (live) </label>
          </form>
          <BarPlotLive />
        </div>
      )
    }
  }
}


export default Livedaten