import React from 'react';
import './Budget.css';
import BudgetPlotLive from '../components/Plot/BudgetPlotLive';

class Budget extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            // starttime: new Date(2023, 1, 20, 8, 0, 0) * 1,
            date: new Date()
        }
    }

    render() {
        return (
            <div
                style={{
                    display: "flex",
                    flexDirection: "column",
                    width: "100%",
                    flexWrap: "wrap",
                    justifyContent: "center",
                }}
            >
                <div className="parent">
                    <BudgetPlotLive
                        // starttime={this.state.starttime}
                        // endtime={
                        //     new Date(this.state.date).setHours(0, 0, 0, 0) ===
                        //         new Date().setHours(0, 0, 0, 0)
                        //         ? Date.now() * 1
                        //         : this.state.date.setHours(23, 59, 59, 999)
                        // }
                        date={this.state.date}
                    />
                </div>
            </div>
        )
    }

}

export default Budget