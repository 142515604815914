import React, { useCallback, useEffect, useState } from "react";
import { Calendar } from "react-calendar";
import Phases from "../components/Admin/Phases";
import BudgetSettings from "../components/Admin/BudgetSettings";
import {
  Modal,
  InputNumber,
  Toggle,
  Input,
  Button,
  Radio,
  RadioGroup,
  DateRangePicker,
  ButtonGroup,
} from "rsuite";
import { format } from "date-fns";
import "rsuite/dist/rsuite.min.css";
import { startOfDay, endOfDay } from "date-fns";
import keinAnreiz from "../assets/keinAnreiz.png";

export default function Admin() {
  const [formDate, setFormDate] = useState(startOfDay(new Date()));

  const [wohnphase, setWohnphase] = useState(false);
  const [wohnphaseName, setWohnphaseName] = useState("");
  const [wohnphaseStart, setWohnphaseStart] = useState(new Date());
  const [wohnphaseEnd, setWohnphaseEnd] = useState(new Date());

  const [phasesEnabled, setPhasesEnabled] = useState(true);
  const [phaseValues, setPhaseValues] = useState([]);

  const [budgetEnabled, setBudgetEnabled] = useState(true);
  const [budgetValues, setBudgetValues] = useState([]);
  // const [budgetStartDate, setBudgetStartDate] = useState(startOfDay(new Date()));
  // const [budgetEndDate, setBudgetEndDate] = useState(endOfDay(new Date()));

  const [pointsEnabled, setPointsEnabled] = useState(true);
  const [points, setPoints] = useState(0);

  const [storylineEnabled, setStorylineEnabled] = useState(true);
  const [multiplikator, setMultiplikator] = useState(1);
  const [storylineName, setStorylineName] = useState("");

  const [geschafft, setGeschafft] = useState("null");

  const [modalOpen, setModalOpen] = useState(false);
  const handleOpen = () => setModalOpen(true);
  const handleClose = () => setModalOpen(false);

  function toISOLocal(d) {
    var z = n => ('0' + n).slice(-2);
    // var zz = n => ('00' + n).slice(-3);
    // var off = d.getTimezoneOffset();
    // var sign = off > 0 ? '-' : '+';
    // off = Math.abs(off);

    return d.getFullYear() + '-'
      + z(d.getMonth() + 1) + '-' +
      z(d.getDate())
    //  + 'T'
    // z(d.getHours()) + ':' +
    // z(d.getMinutes()) + ':' +
    // z(d.getSeconds()) + '.' +
    // zz(d.getMilliseconds()) +
    // sign + z(off / 60 | 0) + ':' + z(off % 60);
  }

  const getAnreiz = useCallback(() => {
    fetch(
      `https://smarthome.iip.kit.edu/api/api/getAnreiz/${formDate.getDate()}-${formDate.getMonth() + 1
      }-${formDate.getFullYear()}/`,
      {
        method: "GET",
        credentials: "include",
      }
    )
      .then((response) => {
        if (response.ok) {
          return response.json();
        } else {
          return Promise.reject(response.status);
        }
      })
      .then((data) => {
        let result = [];
        if (data.length > 0) {
          if (
            data[0].start_timestamp !== null &&
            data[0].end_timestamp !== null &&
            data[0].limit !== null &&
            data[0].mode !== null
          ) {
            let number = 1;
            for (const element of data) {
              result.push({
                number: number,
                start: element.start_timestamp,
                end: element.end_timestamp,
                limit: element.limit,
                mode: element.mode,
              });
              number = number + 1;
            }
          }
          if (
            data[0].storyline_multiplikator !== null &&
            data[0].storyline_name !== null
          ) {
            setMultiplikator(data[0].storyline_multiplikator);
            setStorylineName(data[0].storyline_name);
          } else {
            setMultiplikator(1);
            setStorylineName("");
          }
          if (data[0].reward_points !== null) {
            setPoints(data[0].reward_points);
          } else {
            setPoints(0);
          }
          setGeschafft(`${data[0].geschafft}`.toLowerCase());
        } else {
          setPhasesEnabled(false);
          setStorylineEnabled(false);
          setPointsEnabled(false);
          setGeschafft("null");
        }
        setPhaseValues(result);
      })
      .catch((error) => console.log(error));

    fetch(
      `https://smarthome.iip.kit.edu/api/api/getBudget/${formDate.getFullYear()}-${formDate.getMonth() + 1
      }-${formDate.getDate()}/`,
      {
        method: "GET",
        credentials: "include",
      }
    )
      .then((response) => {
        if (response.ok) {
          return response.json();
        } else {
          return Promise.reject(response.status);
        }
      })
      .then((data) => {
        let result = [];
        if (data.length > 0) {
          if (
            data[0].start_timestamp !== null &&
            data[0].end_timestamp !== null &&
            data[0].start_date !== null &&
            data[0].end_date !== null &&
            data[0].budget !== null &&
            data[0].modus !== null
          ) {
            let number = 1;
            for (const element of data) {
              result.push({
                number: number,
                start: element.start_timestamp,
                end: element.end_timestamp,
                start_date: new Date(element.start_date).toISOString().split('T')[0],
                end_date: new Date(element.end_date).toISOString().split('T')[0],
                budget: element.budget,
                modus: element.modus,
              });
              number = number + 1;
            }
          }
          if (
            data[0].storyline_multiplikator !== null &&
            data[0].storyline_name !== null
          ) {
            setMultiplikator(data[0].storyline_multiplikator);
            setStorylineName(data[0].storyline_name);
          } else {
            setMultiplikator(1);
            setStorylineName("");
          }
          if (data[0].reward_points !== null) {
            setPoints(data[0].reward_points);
          } else {
            setPoints(0);
          }
          setGeschafft(`${data[0].geschafft}`.toLowerCase());
        } else {
          setBudgetEnabled(false);
          setStorylineEnabled(false);
          setPointsEnabled(false);
          setGeschafft("null");
        }
        setBudgetValues(result);
      })
      .catch((error) => console.log(error));

    fetch(
      `https://smarthome.iip.kit.edu/api/api/getWohnphase/${formDate * 1}/`,
      {
        method: "GET",
        credentials: "include",
      }
    )
      .then((response) => {
        if (response.ok) {
          return response.json();
        } else {
          return Promise.reject(response.status);
        }
      })
      .then((data) => {
        if (data.length === 1) {
          setWohnphase(true);
          setWohnphaseName(data[0].name);
          setWohnphaseStart(new Date(data[0].startts));
          setWohnphaseEnd(new Date(data[0].endts));
        } else {
          setWohnphase(false);
          setWohnphaseName("");
          setWohnphaseStart(startOfDay(formDate));
          setWohnphaseEnd(endOfDay(formDate));
        }
      });
  }, [formDate]);

  useEffect(() => {
    setPhasesEnabled(phaseValues.length === 0 ? false : true);
  }, [phaseValues]);

  useEffect(() => {
    setStorylineEnabled(
      multiplikator === 1 && storylineName === "" ? false : true
    );
  }, [multiplikator, storylineName]);

  useEffect(() => {
    setPointsEnabled(points === 0 ? false : true);
  }, [points]);

  useEffect(() => {
    getAnreiz();
    setPoints(0);
    setMultiplikator(1);
    setStorylineName("");
    setGeschafft("null");
  }, [formDate, getAnreiz]);

  async function saveAnreiz() {
    let pass = geschafft;
    const csrfToken = getCookie("csrf_access_token");

    await fetch("https://smarthome.iip.kit.edu/api/api/saveAnreiz/", {
      method: "POST",
      credentials: "include",
      headers: {
        "X-CSRF-TOKEN": csrfToken,
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        date: format(formDate, "dd-MM-yyyy"),
        phases: phasesEnabled ? phaseValues : [],
        points: pointsEnabled ? points : 0,
        multiplikator: storylineEnabled && storylineName ? multiplikator : 1,
        storylineName: storylineEnabled ? storylineName : "",
        geschafft: formDate * 1 <= startOfDay(Date.now()) * 1 ? pass : "null",
      }),
    })

      .then((response) => {
        if (response.ok) {
          return response.json();
        } else {
          return Promise.reject("some other error: " + response.status);
        }
      })
      .then((data) => {
        let result = [];
        if (data.length > 0) {
          if (
            data[0].start_timestamp !== null &&
            data[0].end_timestamp !== null &&
            data[0].limit !== null &&
            data[0].mode !== null
          ) {
            let number = 1;
            for (const element of data) {
              result.push({
                number: number,
                start: element.start_timestamp,
                end: element.end_timestamp,
                limit: element.limit,
                mode: element.mode,
              });
              number = number + 1;
            }
          }
          if (
            data[0].storyline_multiplikator !== null &&
            data[0].storyline_name !== null
          ) {
            setMultiplikator(data[0].storyline_multiplikator);
            setStorylineName(data[0].storyline_name);
          } else {
            setMultiplikator(1);
            setStorylineName("");
          }
          if (data[0].reward_points !== null) {
            setPoints(data[0].reward_points);
          } else {
            setPoints(0);
          }
          setGeschafft(`${data[0].geschafft}`.toLowerCase());
        } else {
          setPhasesEnabled(false);
          setStorylineEnabled(false);
          setPointsEnabled(false);
          setGeschafft("null");
        }
        setPhaseValues(result);
      })
      .catch((error) => console.log(error));

    if (formDate * 1 < startOfDay(Date.now()) * 1) {
      fetch(
        `https://smarthome.iip.kit.edu/api/api/anreizAuswerten/${formDate.getDate()}-${formDate.getMonth() + 1
        }-${formDate.getFullYear()}/`,
        {
          method: "GET",
          credentials: "include",
        }
      )
        .then((response) => {
          return response.json();
        })
        .then((data) => {
          setGeschafft(data["geschafft"]);
        })
        .catch((error) => console.log(error));
    }
  }

  async function saveBudget() {
    let pass = geschafft;
    const csrfToken = getCookie("csrf_access_token");

    await fetch("https://smarthome.iip.kit.edu/api/api/saveBudget/", {
      method: "POST",
      credentials: "include",
      headers: {
        "X-CSRF-TOKEN": csrfToken,
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        budgets: budgetEnabled ? budgetValues : [],
        // start_date: budgetEnabled ? "2023-02-23" : [],
        // end_date: budgetEnabled ? "2023-02-24" : [],
        points: pointsEnabled ? points : 0,
        multiplikator: storylineEnabled && storylineName ? multiplikator : 1,
        storylineName: storylineEnabled ? storylineName : "",
        geschafft: formDate * 1 <= startOfDay(Date.now()) * 1 ? pass : "null",
      }),
    })

      .then((response) => {
        if (response.ok) {
          return response.json();
        } else {
          return Promise.reject("some other error: " + response.status);
        }
      })
      .then((data) => {
        let result = [];
        if (data.length > 0) {
          if (
            data[0].start_timestamp !== null &&
            data[0].end_timestamp !== null &&
            data[0].start_date !== null &&
            data[0].end_date !== null &&
            data[0].budget !== null &&
            data[0].modus !== null
          ) {
            let number = 1;
            for (const element of data) {
              result.push({
                number: number,
                start: element.start_timestamp,
                end: element.end_timestamp,
                start_date: toISOLocal(new Date(element.start_date)),
                end_date: toISOLocal(new Date(element.end_date)),
                budget: element.budget,
                modus: element.modus,
              });
              number = number + 1;
            }
          }
          if (
            data[0].storyline_multiplikator !== null &&
            data[0].storyline_name !== null
          ) {
            setMultiplikator(data[0].storyline_multiplikator);
            setStorylineName(data[0].storyline_name);
          } else {
            setMultiplikator(1);
            setStorylineName("");
          }
          if (data[0].reward_points !== null) {
            setPoints(data[0].reward_points);
          } else {
            setPoints(0);
          }
          setGeschafft(`${data[0].geschafft}`.toLowerCase());
        } else {
          setBudgetEnabled(false);
          setStorylineEnabled(false);
          setPointsEnabled(false);
          setGeschafft("null");
        }
        setBudgetValues(result);
      })
      .catch((error) => console.log(error));

    if (formDate * 1 < startOfDay(Date.now()) * 1) {
      fetch(
        `https://smarthome.iip.kit.edu/api/api/anreizAuswerten/${formDate.getDate()}-${formDate.getMonth() + 1
        }-${formDate.getFullYear()}/`,
        {
          method: "GET",
          credentials: "include",
        }
      )
        .then((response) => {
          return response.json();
        })
        .then((data) => {
          setGeschafft(data["geschafft"]);
        })
        .catch((error) => console.log(error));
    }
  }

  function saveWohnphase() {
    const csrfToken = getCookie("csrf_access_token");
    fetch(`https://smarthome.iip.kit.edu/api/api/saveWohnphase/`, {
      method: "POST",
      credentials: "include",
      headers: {
        "X-CSRF-TOKEN": csrfToken,
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        name: wohnphaseName,
        startts: wohnphaseStart * 1,
        endts: wohnphaseEnd * 1,
      }),
    })
      .then((response) => {
        if (response.ok) {
          return;
        } else {
          return Promise.reject("some other error: " + response.status);
        }
      })
      .then(() => {
        if (
          formDate * 1 >= wohnphaseStart * 1 &&
          formDate * 1 <= wohnphaseEnd * 1
        ) {
          console.log(
            formDate * 1 >= wohnphaseStart * 1 &&
            formDate * 1 <= wohnphaseEnd * 1
          );
          setWohnphase(true);
        } else {
          setWohnphase(false);
        }
      })
      .then(() => {
        getAnreiz();
      })
      .catch((error) => {
        console.log(error);
      });
  }

  const handleFile = (event, type) => {
    const csrfToken = getCookie("csrf_access_token");
    const formData = new FormData();
    formData.append(
      "fileupload",
      event.target.files[0],
      event.target.files[0].name
    );

    fetch(
      `https://smarthome.iip.kit.edu/api/api/saveAnreizBilder/${formDate.getDate()}-${formDate.getMonth() + 1
      }-${formDate.getFullYear()}/${type}/`,
      {
        method: "POST",
        credentials: "include",
        headers: {
          "X-CSRF-TOKEN": csrfToken,
        },
        body: formData,
        dataType: "jsonp",
      }
    )
      .then((response) => {
        if (response.ok) {
          return response.blob();
        }
      })
      .then((data) => {
        switch (type) {
          case "anreiz":
            console.log("hi");
            document.getElementById("picAnreizPreview").src =
              URL.createObjectURL(data);
            break;
          case "hintergrund":
            console.log("hi");
            document.getElementById("picHintergrundPreview").src =
              URL.createObjectURL(data);
            break;
          case "tipps":
            console.log("hi");
            document.getElementById("picTippsPreview").src =
              URL.createObjectURL(data);
            break;
          default:
            break;
        }
      })
      .catch((error) => console.log(error));
  };

  function getCookie(name) {
    // Funktion, die das vorhanden Sein eines bestimmten Cookies prüft.
    if (!document.cookie) {
      return null;
    }

    const xsrfCookies = document.cookie
      .split(";")
      .map((c) => c.trim())
      .filter((c) => c.startsWith(name + "="));

    if (xsrfCookies.length === 0) {
      return null;
    }

    return decodeURIComponent(xsrfCookies[0].split("=")[1]);
  }

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "row",
        width: "100%",
        justifyContent: "space-evenly",
        alignContent: "flex-start",
      }}
    >
      <div style={{ width: "35%" }}>
        <Calendar onChange={setFormDate} value={formDate}></Calendar>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            textAlign: "center",
            marginTop: "2%",
          }}
        >
          Wohnphase
          <div style={{ margin: "0 auto", width: "70%" }}>
            <Input
              placeholder="Name der Wohnphase"
              value={wohnphaseName}
              style={{ margin: "1% 0" }}
              onChange={setWohnphaseName}
            ></Input>
          </div>
          <DateRangePicker
            style={{ margin: "1% auto", width: "70%" }}
            onOk={(value) => {
              setWohnphaseStart(startOfDay(value[0]));
              setWohnphaseEnd(endOfDay(value[1]));
            }}
            value={[wohnphaseStart, wohnphaseEnd]}
            cleanable={false}
            isoWeek
            showOneCalendar
          ></DateRangePicker>
          <Button
            appearance="primary"
            style={{ alignSelf: "end", margin: "1% auto" }}
            onClick={() => {
              handleOpen();
            }}
            disabled={wohnphaseName === ""}
          >
            Wohnphase hinzufügen
          </Button>
          <Modal open={modalOpen} onClose={handleClose}>
            <p>Wohnphase hinzufügen?</p>
            <p>
              Alle Anreize in diesem Zeitraum und in sich eventuell
              überschneidenden Wohnphasen werden gelöscht.
            </p>
            <p></p>
            <div style={{ display: "flex", justifyContent: "center" }}>
              <Button
                appearance="primary"
                style={{ alignSelf: "end", margin: "1% auto" }}
                onClick={() => {
                  saveWohnphase();
                  handleClose();
                }}
              >
                Ja
              </Button>
              <Button
                appearance="primary"
                style={{ alignSelf: "end", margin: "1% auto" }}
                onClick={() => {
                  handleClose();
                }}
              >
                Nein
              </Button>
            </div>
          </Modal>
        </div>
      </div>
      {wohnphase ? (
        <div style={{ display: "flex", flexDirection: "column", width: "60%" }}>
          <div
            style={{
              display: "inline-flex",
              margin: "1% 0",
              justifyContent: "space-evenly",
            }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                textAlign: "center",
                width: "25%",
              }}
            >
              Anreiz
              <img
                id="picAnreizPreview"
                width={"100%"}
                style={{ marginBottom: "5%" }}
                src={`https://smarthome.iip.kit.edu/api/api/getAnreizBilder/${formDate.getDate()}-${formDate.getMonth() + 1
                  }-${formDate.getFullYear()}/anreiz/?ts=${Date.now()}`}
                onError={(e) => {
                  e.target.onerror = null;
                  e.target.src = keinAnreiz;
                }}
                alt="Anreize konnten nicht gefunden werden."
              ></img>
              <input
                type="file"
                accept="image/png"
                onChange={(event) => {
                  handleFile(event, "anreiz");
                }}
                style={{ alignSelf: "center" }}
              ></input>
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                textAlign: "center",
                width: "25%",
              }}
            >
              Hintergründe
              <img
                id="picHintergrundPreview"
                width={"100%"}
                style={{ marginBottom: "5%" }}
                src={`https://smarthome.iip.kit.edu/api/api/getAnreizBilder/${formDate.getDate()}-${formDate.getMonth() + 1
                  }-${formDate.getFullYear()}/hintergrund/?ts=${Date.now()}`}
                onError={(e) => {
                  e.target.onerror = null;
                  e.target.src = keinAnreiz;
                }}
                alt="Hintergründe konnten nicht gefunden werden."
              ></img>
              <input
                type="file"
                accept="image/png"
                onChange={(event) => {
                  handleFile(event, "hintergrund");
                }}
                style={{ alignSelf: "center" }}
              ></input>
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                textAlign: "center",
                width: "25%",
              }}
            >
              Tipps
              <img
                id="picTippsPreview"
                width={"100%"}
                style={{ marginBottom: "5%" }}
                src={`https://smarthome.iip.kit.edu/api/api/getAnreizBilder/${formDate.getDate()}-${formDate.getMonth() + 1
                  }-${formDate.getFullYear()}/tipps/?ts=${Date.now()}`}
                onError={(e) => {
                  e.target.onerror = null;
                  e.target.src = keinAnreiz;
                }}
                alt="Tipps konnten nicht gefunden werden."
              ></img>
              <input
                type="file"
                accept="image/png"
                onChange={(event) => {
                  handleFile(event, "tipps");
                }}
                style={{ alignSelf: "center" }}
              ></input>
            </div>
          </div>
          <div
            style={{ display: "flex", flexDirection: "row", margin: "1% 0" }}
          >
            <Toggle
              style={{ marginRight: "1em" }}
              checked={phasesEnabled}
              onChange={setPhasesEnabled}
            ></Toggle>
            Leistungskurve
          </div>
          <div
            style={{
              width: "100%",
              display: phasesEnabled ? "flex" : "none",
              justifyContent: "space-evenly",
              margin: "1% 0",
              flexDirection: "column",
            }}
          >
            <Phases
              date={format(formDate, "yyyy-MM-dd")}
              phaseValues={phaseValues}
              onChange={setPhaseValues}
              style={{ display: phasesEnabled ? "flex" : "none" }}
            ></Phases>
          </div>
          <div
            style={{ display: "flex", flexDirection: "row", margin: "1% 0" }}
          >
            <Toggle
              style={{ marginRigt: "1em" }}
              checked={budgetEnabled}
              onChange={setBudgetEnabled}
            ></Toggle>
            CO2-Budget
          </div>
          <div
            style={{
              width: "100%",
              display: budgetEnabled ? "flex" : "none",
              justifyContent: "space-evenly",
              margin: "1% 0",
              flexDirection: "column",
            }}
          >
            <BudgetSettings
              startDate={format(formDate, "yyyy-MM-dd")}
              endDate={format(formDate, "yyyy-MM-dd")}
              budgetValues={budgetValues}
              onChange={setBudgetValues}
              style={{ display: budgetEnabled ? "flex" : "none" }}
            ></BudgetSettings>
          </div>
          <div
            style={{ display: "flex", flexDirection: "row", margin: "1% 0" }}
          >
            <Toggle
              style={{ marginRight: "1em" }}
              checked={pointsEnabled}
              onChange={setPointsEnabled}
            ></Toggle>
            Belohnungspunkte
          </div>
          <div
            style={{
              display: pointsEnabled ? "inline-flex" : "none",
              justifyContent: "space-evenly",
              width: "100%",
              margin: "1% 0",
            }}
          >
            <div>
              <InputNumber
                value={points}
                onChange={(value) => setPoints(value)}
                step={10}
              ></InputNumber>
            </div>
            <div>
              <Input style={{ visibility: "hidden" }}></Input>
            </div>
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              margin: "1% 0",
              alignItems: "center",
            }}
          >
            <Toggle
              style={{ marginRight: "1em" }}
              checked={storylineEnabled}
              onChange={setStorylineEnabled}
            ></Toggle>
            Storylines
          </div>
          <div
            style={{
              display: storylineEnabled ? "inline-flex" : "none",
              width: "100%",
              margin: "1% 0",
              justifyContent: "space-evenly",
            }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                textAlign: "center",
              }}
            >
              Multiplikator
              <InputNumber
                value={multiplikator}
                onChange={setMultiplikator}
              ></InputNumber>
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                textAlign: "center",
              }}
            >
              Name
              <Input value={storylineName} onChange={setStorylineName}></Input>
            </div>
          </div>
          <div style={{ alignSelf: "end", margin: "1% 0", display: "flex" }}>
            <RadioGroup onChange={setGeschafft} value={geschafft} inline>
              <Radio value={"null"}>Automatisch</Radio>
              <Radio value={"true"}>Geschafft</Radio>
              <Radio value={"false"}>Nicht Geschafft</Radio>
            </RadioGroup>
          </div>
          {/* <Button
            appearance="primary"
            style={{ alignSelf: "end", margin: "1% 0" }}
            onClick={() => {
              saveAnreiz();
            }}
          >
            Anreiz Speichern
          </Button> */}

          <ButtonGroup>
            <Button
              appearance="primary"
              style={{ alignSelf: "end", margin: "1% 0" }}
              onClick={() => {
                saveAnreiz();
              }}
            >
              Anreiz Speichern
            </Button>
            <Button
              appearance="primary"
              style={{ alignSelf: "end", margin: "1% 0" }}
              onClick={() => {
                saveBudget();
              }}
            >
              Budget Speichern
            </Button>
          </ButtonGroup>
        </div>
      ) : (
        <></>
      )}
    </div>
  );
}
