import React, { useEffect, useState } from "react";
import { ProgressBar } from "react-bootstrap";

export default function EarthDay(props) {
  const [consumed, setConsumed] = useState(0);
  const [earthsConsumed, setEarthsConsumed] = useState(0);

  useEffect(() => {
    var d = new Date();
    d.setDate(d.getDate() - 14);

    const requestOptions = {
      method: "GET",
      credentials: "include",
    };

    fetch(
      "https://smarthome.iip.kit.edu/api/api/getmaxandmean/Leistung_Gesamt/" +
      (props.start === "" ? d * 1 : props.start) +
      "/" +
      Date.now() +
      "/",
      requestOptions
    )
      .then((response) => {
        if (response.ok) {
          return response.json();
        }
      })
      .then((data) => setConsumed(data.kwh))
      .catch((error) => {
        console.log(error);
      });
  }, [props]);

  useEffect(() => {
    const deutscher_durchschnitt = 2000 / 365.25;
    const eshl_durchschnitt =
      consumed /
      Math.ceil(
        (Date.now() -
          (props.start === ""
            ? new Date().setDate(new Date().getDate() - 14) * 1
            : props.start)) /
        (1000 * 3600 * 24)
      );
    setEarthsConsumed(
      2.9435483871 * (eshl_durchschnitt / deutscher_durchschnitt)
    );
  }, [consumed, props.start]);

  return (
    <div style={{ width: "60%", marginBottom: "2%" }}>
      <p style={{ textAlign: "center" }}>
        Du verbrauchst {Math.round(earthsConsumed * 100) / 100} Erden.
      </p>
      <ProgressBar>
        <ProgressBar
          max={4}
          variant="success"
          now={earthsConsumed > 1 ? 1 : earthsConsumed}
        ></ProgressBar>
        <ProgressBar
          max={4}
          variant="danger"
          now={earthsConsumed > 1 ? earthsConsumed - 1 : 0}
        ></ProgressBar>
      </ProgressBar>
    </div>
  );
}

// Grundlage für die Berechnung ist einmal die Annahme, dass Deutschland ca. 3 Erden „verbraucht“ (https://www.tagesschau.de/ausland/unicef-ressourcen-verbrauch-101.html, https://de.statista.com/infografik/10574/benoetigte-erden-je-lebensstil-ausgewaehlter-laender/) und zum anderen die Annahme, dass ein 2-Personen Haushalt in Deutschland durchschnittlich ca. 2000kWh Strom im Jahr verbraucht (https://strom-report.de/stromverbrauch/). Dann wird euer Stromverbrauch der letzten 14 Tage bestimmt und auf ein Jahr hochgerechnet. Dieser Wert wird dann mit dem Durchschnittsverbrauch von 2000kWh verglichen und damit die Erden-Angabe bestimmt (also wenn euer hochgerechneter Stromverbrauch z.B. 2000kWh beträgt, verbraucht ihr laut der Angabe ca. 3 Erden, wenn der hochgerechnete Stromverbrauch 1000kWh beträgt, sind es 1,5). Es wird also im Endeffekt davon ausgegangen, dass der Erdenverbrauch nur vom Stromverbrauch abhängt, was natürlich nicht korrekt ist und eine sehr vereinfachte Sichtweise darstellt. 