import React from 'react';
import * as FaIcons from 'react-icons/fa';
import * as AiIcons from 'react-icons/ai';
import * as IoIcons from 'react-icons/io';
import * as TbIcons from 'react-icons/tb';

// Daten für das Auswahlmenü der Navigationsleiste

export const SidebarData = [
  {
    title: 'Home',
    path: '/',
    icon: <AiIcons.AiFillHome />,
    cName: 'nav-text'
  },
  {
    title: 'Livedaten',
    path: '/livedaten',
    icon: <AiIcons.AiOutlineLineChart />,
    cName: 'nav-text'
  },
  {
    title: 'CO2-Budget',
    path: 'budget',
    icon: <TbIcons.TbLayoutNavbar />,
    cName: 'nav-text'
  },
  {
    title: 'Kalender',
    path: '/kalender',
    icon: <FaIcons.FaCalendarAlt />,
    cName: 'nav-text'
  },
  {
    title: 'Funktionsweise',
    path: '/funktionsweise',
    icon: <IoIcons.IoMdHelpCircle />,
    cName: 'nav-text'
  },
  {
    title: 'Kontakt',
    path: '/kontakt',
    icon: <FaIcons.FaPhoneAlt />,
    cName: 'nav-text'
  },
  {
    title: 'Admin',
    path: '/admin',
    icon: <IoIcons.IoIosSettings />,
    cName: 'nav-text'
  }
];