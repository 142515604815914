import React, {useState} from 'react';
import Tag from '../components/Kalender/Tag.js';
import Zeitspanne from '../components/Kalender/Zeitspanne';
import './Kalender.css'

// Die Kalenderseite ruft standardmäßig den Tag-Komponenten auf, wenn vom User ausgewählt auch den Zeitspannen-Komponent

export default function Kalender() {

    const [ anzeige, setAnzeige ] = useState('kalender')

    function onChangeValue(event) {                // Funktion, die auf einen Wechsel der Auswahl der Anzeige reagiert.
        setAnzeige(event.target.value);
    }

    if(anzeige === "zeitspanne") {
        return(
            <div className="KalenderSeite">
                <form  className='tagForm' onChange={onChangeValue}>
                    <input id="e1" type='radio' name='auswahl' value='kalender'/><label className='labelClass' htmlFor="e1"> Tag </label>
                    <input id="e2" type='radio' name='auswahl' value='zeitspanne' defaultChecked/><label className='labelClass' htmlFor="e2"> Zeitspanne </label>
                </form>
                <Zeitspanne/>
            </div>
        )
    }
    else {
        return(
            <div className="KalenderSeite">
                <form  className='tagForm' onChange={onChangeValue}>
                    <input id="e3" type='radio' name='auswahl' value='kalender' defaultChecked/><label className='labelClass' htmlFor="e3"> Tag </label>
                    <input id="e4" type='radio' name='auswahl' value='zeitspanne'/><label className='labelClass' htmlFor="e4"> Zeitspanne </label>
                </form>
                <Tag/>
            </div>
        )
    }
}