import React, { useEffect, useState } from 'react';
import { Button, DateRangePicker, InputNumber, SelectPicker } from 'rsuite';
import { startOfDay, endOfDay } from 'date-fns';

export default function BudgetSettings(props) {
    const [budgetValues, setBudgetValues] = useState([])

    useEffect(() => {
        setBudgetValues(props.budgetValues)
    }, [props.budgetValues])

    function addBudget() {
        setBudgetValues(
            [...budgetValues,
            {
                number: budgetValues.length + 1,
                start: startOfDay(new Date(Date.parse(props.startDate))) * 1,
                end: endOfDay(new Date(Date.parse(props.endDate))) * 1,
                start_date: new Date(Date.parse(props.startDate) * 1),
                end_date: new Date(Date.parse(props.endDate) * 1),
                // start_date: format(new Date(Date.parse(props.startDate)), "dd-MM-yyyy"),
                // end_date: new Date(Date.parse(props.endDate)),
                modus: 'max',
                budget: 0
            }]
        )
        props.onChange(
            [...budgetValues,
            {
                number: budgetValues.length + 1,
                start: startOfDay(new Date(Date.parse(props.startDate))) * 1,
                end: endOfDay(new Date(Date.parse(props.endDate))) * 1,
                start_date: new Date(Date.parse(props.startDate) * 1),
                end_date: new Date(Date.parse(props.endDate) * 1),
                // end_date: new Date(Date.parse(props.endDate)),
                modus: 'max',
                budget: 0
            }]
        )
    }

    function toISOLocal(d) {
        var z = n => ('0' + n).slice(-2);
        // var zz = n => ('00' + n).slice(-3);
        var off = d.getTimezoneOffset();
        // var sign = off > 0 ? '-' : '+';
        off = Math.abs(off);

        return d.getFullYear() + '-'
            + z(d.getMonth() + 1) + '-' +
            z(d.getDate())
        //  + 'T'
        // z(d.getHours()) + ':' +
        // z(d.getMinutes()) + ':' +
        // z(d.getSeconds()) + '.' +
        // zz(d.getMilliseconds()) +
        // sign + z(off / 60 | 0) + ':' + z(off % 60);
    }

    function deleteBudget() {
        setBudgetValues([...budgetValues].slice(0, -1))
        props.onChange([...budgetValues].slice(0, -1))
    }

    function inputBudget() {
        const budgetComponents = []
        if (budgetValues?.length > 0) {
            for (const budgetValue of budgetValues) {
                budgetComponents.push(
                    <div style={{ display: 'flex', flexDirection: 'column', marginBottom: '2%' }} key={budgetValue.number}>
                        <div style={{ display: 'flex', justifyContent: 'space-evenly', alignItems: 'center' }}>
                            <span>
                                Budget {budgetValue.number}
                            </span>
                            <DateRangePicker
                                format="yyyy-MM-dd"
                                placement="auto"
                                onOk={value => {
                                    const found = budgetValues?.find(test => test.number === budgetValue.number)
                                    found.start_date = toISOLocal(value[0])
                                    found.end_date = toISOLocal(value[1])
                                    found.start = value[0] * 1
                                    found.end = endOfDay(new Date(Date.parse(value[1]))) * 1
                                    setBudgetValues([...budgetValues])
                                    props.onChange([...budgetValues])
                                }}
                                // ranges={[{
                                //     label: 'Zeitraum',
                                //     value: [startOfDay(new Date(Date.parse(props.startDate))), endOfDay(new Date(Date.parse(props.Date)))]
                                // }]}
                                value={[new Date(budgetValue.start), new Date(budgetValue.end)]}
                                cleanable={false}
                            />
                            {/* <SelectPicker
                                data={[{ "label": "Maximalwert", "value": "max" }, { "label": "Durchschnitt", "value": "mean" }]}
                                value={phaseValue.mode}
                                cleanable={false}
                                searchable={false}
                                onChange={value => {
                                    const found = phaseValues?.find(test => test.number === phaseValue.number)
                                    found.mode = value
                                    setPhaseValues([...phaseValues])
                                    props.onChange([...phaseValues])
                                }}
                            /> */}
                            < div style={{ width: '180px' }}>
                                <InputNumber
                                    postfix="kg CO2"
                                    defaultValue={0}
                                    step={10}
                                    min={0}
                                    onChange={value => {
                                        const found = budgetValues?.find(test => test.number === budgetValue.number)
                                        found.budget = value
                                        setBudgetValues([...budgetValues])
                                        props.onChange([...budgetValues])
                                    }}
                                    value={budgetValue.budget}
                                />
                            </div>
                        </div>
                    </div >
                )
            }
        }

        return budgetComponents
    }

    return (
        <>
            <div style={{ width: '100%', display: 'flex', justifyContent: 'space-evenly', marginBottom: '2%' }}>
                <Button
                    onClick={addBudget}
                    style={{ width: '10%' }}
                    appearance="primary"
                >
                    +
                </Button>
                <Button
                    onClick={deleteBudget}
                    disabled={budgetValues?.length === 0}
                    style={{ width: '10%' }}
                    appearance="primary"
                >
                    -
                </Button>
            </div>
            <div>
                {inputBudget()}
            </div>
        </>
    )
}