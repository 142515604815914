import React, { Component } from "react";
import * as IoIcons from "react-icons/io";
import "./Kennzahlen.css";

/* Dieser Komponent stellt das Maximum, den Durchschnitt und die gesamte verbrauchte Leistung dar. */

class Kennzahlen extends Component {
  constructor(props) {
    super(props);
    this.state = {
      max: null,
      mean: null,
      sum: null,
      leistung: [],
      timestamps: [],
      phases: [],
      current_phase: {},
      durchschnitt_soll: 0,
      loading: true,
      datamissing: false,
      geschafft: "true",
      anreiz: false,
    };
  }

  /* Wenn die Startseite geöffnet wird, soll Kennzahlen aktualisieren */

  componentDidMount() {
    this.getPhases();
  }

  /* Ein Überbleibsel davon, als dieser Komponent noch in der Kalenderansicht genutzt wurde. Jetzt kann man diese Funktion nicht mehr ohne weiteres */
  /* Wenn sich irgendetwas an den Aufrufparameter des Komponenten ändert und das Datum nicht in der Zukunft liegen, werden Daten für einen neuen Plot abgerufen. */

  componentDidUpdate(prevProps) {
    if (
      (this.props.starttime !== prevProps.starttime ||
        this.props.endtime !== prevProps.endtime) &&
      new Date(this.props.starttime).setHours(0, 0, 0, 0) <= new Date()
    ) {
      this.getPhases();
    }
  }

  getPhases() {
    this.setState({ loading: true });

    fetch(
      `https://smarthome.iip.kit.edu/api/api/getAnreiz/${new Date().getDate()}-${new Date().getMonth() + 1
      }-${new Date().getFullYear()}/`,
      {
        method: "GET",
        credentials: "include",
      }
    )
      .then((response) => {
        if (response.ok) {
          return response.json();
        } else {
          return Promise.reject(response.status);
        }
      })
      .then((data) => {
        let result = [];
        if (data?.length > 0) {
          this.setState({ anreiz: true });
          if (
            (data[0].start_timestamp !== null &&
              data[0].end_timestamp !== null) ||
            data[0].limit !== null ||
            data[0].mode !== null ||
            data[0].geschafft !== "null"
          ) {
            let number = 1;
            for (const element of data) {
              result.push({
                number: number,
                start: element.start_timestamp,
                end: element.end_timestamp,
                limit: element.limit,
                mode: element.mode,
                reward_points: element.reward_points,
                storyline_multiplikator: element.storyline_multiplikator,
                storyline_name: element.storyline_name,
                geschafft: data[0].geschafft,
              });
              number = number + 1;
            }
            this.setState({ phases: result });
          } else if (
            data[0].storyline_name !== "" ||
            data[0].storyline_multiplikator !== 1 ||
            data[0].reward_points !== 0
          ) {
            result.push({
              reward_points: data[0].reward_points,
              storyline_multiplikator: data[0].storyline_multiplikator,
              storyline_name: data[0].storyline_name,
              geschafft: data[0].geschafft,
            });
            this.setState({ phases: result });
          }
        } else {
          this.setState({
            anreiz: false,
            phases: result,
          });
        }
      })
      .then(() => {
        this.fetchData();
      })
      .catch((error) => console.log(error));
  }

  /* fetchData() fragt die Gesamtleistungsdaten des ESHL von der API ab. Hier werden Berechnungen für Max, Mean und Sum von der API ausgeführt.*/

  fetchData() {
    const requestOptions = {
      method: "GET",
      credentials: "include",
    };

    fetch(
      "https://smarthome.iip.kit.edu/api/api/getdata/Leistung_Gesamt/" +
      this.props.starttime +
      "/" +
      this.props.endtime +
      "/min=false/",
      requestOptions
    )
      .then((response) => {
        if (response.ok) {
          return response.json();
        }
      })
      .then((data) => {
        this.setState({
          max: data.max,
          mean: data.mean,
          sum: data.kwh,
          leistung: Object.values(data.myData["Leistung_Gesamt"]),
          timestamps: Object.values(data.myData.Timestamp),
        });
      })
      .then(() => {
        const pass = evaluate(
          this.state.phases,
          this.state.timestamps,
          this.state.leistung
        );
        this.setState({
          geschafft: pass.pass,
          durchschnitt_soll: pass.durchschnitt_soll,
          current_phase: pass.current_phase,
        });
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => {
        this.setState({
          loading: false,
        });
      });
  }

  renderFeedback() {
    if (this.state.anreiz && this.state.phases[0]?.start !== undefined) {
      if (this.state.phases[0]?.geschafft !== 'null') {
        if (this.state.phases[0]?.geschafft === 'true') {
          return (
            <p
              style={{
                fontSize: "150%",
                textAlign: "center",
                marginBottom: "2%",
              }}
            >
              Sehr gut! Du hast den Anreiz geschafft.
            </p>
          );
        } else if (this.state.phases[0]?.geschafft === 'false') {
          return (
            <p
              style={{
                fontSize: "150%",
                textAlign: "center",
                marginBottom: "2%",
              }}
            >
              Schade, Du hast den Anreiz nicht geschafft. Versuche es morgen wieder!
            </p>
          );
        }
      } else if (this.state.geschafft === true || this.state.geschafft === "true") {
        switch (this.state.current_phase.mode) {
          case "max":
            return (
              <p
                style={{
                  fontSize: "150%",
                  textAlign: "center",
                  marginBottom: "2%",
                }}
              >
                {`Weiter so! Bleibe bis ${new Date(
                  this.state.current_phase.end
                ).toLocaleTimeString([], {
                  hour: "2-digit",
                  minute: "2-digit",
                })} Uhr unter einem Maximalwert von ${this.state.current_phase.limit
                  } Watt.`}
              </p>
            );
          case "mean":
            if (this.state.durchschnitt_soll > this.state.current_phase.limit) {
              return (
                <p
                  style={{
                    fontSize: "150%",
                    textAlign: "center",
                    marginBottom: "2%",
                  }}
                >
                  {`Weiter so! Bleibe noch bis ${new Date(
                    this.state.current_phase.end
                  ).toLocaleTimeString([], {
                    hour: "2-digit",
                    minute: "2-digit",
                  })} Uhr durchschnittlich unter einem Wert von ${this.state.durchschnitt_soll
                    } Watt.`}
                </p>
              );
            } else {
              return (
                <p
                  style={{
                    fontSize: "150%",
                    textAlign: "center",
                    marginBottom: "2%",
                  }}
                >
                  {`Das kannst Du besser! Bleibe bis ${new Date(
                    this.state.current_phase.end
                  ).toLocaleTimeString([], {
                    hour: "2-digit",
                    minute: "2-digit",
                  })} Uhr durchschnittlich unter einem Wert von ${this.state.durchschnitt_soll
                    } Watt.`}
                </p>
              );
            }
          default:
            return (
              <p
                style={{
                  fontSize: "150%",
                  textAlign: "center",
                  marginBottom: "2%",
                }}
              >
                Du bist auf dem besten Weg den Anreiz zu schaffen!
              </p>
            )
        }

      } else {
        return (
          <p
            style={{
              fontSize: "150%",
              textAlign: "center",
              marginBottom: "2%",
            }}
          >
            Schade, Du hast den Anreiz nicht geschafft. Versuche es morgen wieder!
          </p>
        );
      }
    } else {
      return
    }
  }

  renderPhases() {
    if (this.state.anreiz) {
      return (
        <div style={{ overflowX: "auto" }}>
          <table
            style={{
              width: "calc(100%)",
              textAlign: "center",
              fontSize: "150%",
              borderCollapse: "separate",
              tableLayout: "fixed",
              minWidth: "370px",
            }}
          >
            {this.state.phases.length > 0 &&
              this.state.phases[0]?.start !== null &&
              this.state.phases[0]?.start !== undefined ? (
              <thead>
                <tr>
                  <th>Zeitraum</th>
                  <th>Art</th>
                  <th>Grenze</th>
                </tr>
              </thead>
            ) : (
              ""
            )}
            <tbody>
              {this.state.anreiz &&
                this.state.phases[0]?.start !== null &&
                this.state.phases[0]?.start !== undefined ? (
                this.state.phases.map((phase) => (
                  <tr key={phase.start}>
                    <td>{`${new Date(phase.start).toLocaleTimeString([], {
                      hour: "2-digit",
                      minute: "2-digit",
                    })} - ${new Date(phase.end).toLocaleTimeString([], {
                      hour: "2-digit",
                      minute: "2-digit",
                    })}`}</td>
                    <td>{phase.mode === "max" ? "Maximum" : "Durchschnitt"}</td>
                    <td>{phase.limit}</td>
                  </tr>
                ))
              ) : (
                <></>
              )}
              {this.state.phases.length > 0 &&
                this.state.phases[0]?.storyline_name !== "" ?
                <tr>
                  <th>Punkte</th>
                  <th>Multiplikator</th>
                  <th>Storyline</th>
                </tr> :
                this.state.reward_points > 0 ?
                  <tr>
                    <th></th>
                    <th>Punkte</th>
                    <th></th>
                  </tr> : ''
              }
              {this.state.phases.length > 0 &&
                this.state.phases[0]?.storyline_name !== "" ?
                <tr>
                  <td>{this.state.phases[0]?.reward_points ?? 0}</td>
                  <td>{this.state.phases[0]?.storyline_multiplikator ?? 0}</td>
                  <td>{this.state.phases[0]?.storyline_name ?? 0}</td>
                </tr> :
                this.state.reward_points > 0 ?
                  <tr>
                    <td></td>
                    <td>{this.state.phases[0]?.reward_points ?? 0}</td>
                    <td></td>
                  </tr> : ''
              }
            </tbody>
          </table>
        </div>
      );
    } else {
      return;
    }
  }

  render() {
    if (new Date(this.props.starttime).setHours(0, 0, 0, 0) > new Date()) {
      // Wenn ein Tag in der Zukunft abgefragt wird
      return (
        <div>
          <p>Niemand kann in die Zukunft schauen :)</p>
        </div>
      );
    } else if (this.state.datamissing === true) {
      // Wenn die Anfrage an die Api schief gelaufen ist.
      return (
        <div>
          <p>Error: Data not found</p>
        </div>
      );
    } else if (this.state.loading === true) {
      return (
        <>
          <button
            id="kennzahlBtn"
            style={{ display: "flex", justifyContent: "space-evenly" }}
            onClick={this.props.onClick}
          >
            <div className="refresh spin" id="refresh">
              <IoIcons.IoMdRefresh />
            </div>
            <p>Loading...</p>
            <div
              className="refresh"
              style={{
                visibility:
                  "hidden" /* Kennzahlen soll im center des buttons liegen. */,
              }}
            >
              <IoIcons.IoMdRefresh />
            </div>
          </button>
          <p
            style={{
              fontSize: "150%",
              textAlign: "center",
              marginBottom: "2%",
            }}
          >
            Loading...
          </p>
        </>
      );
    } else {
      return (
        // Der "eigentliche" Kennzahlen Komponent.
        <>
          <button
            id="kennzahlBtn"
            style={{ display: "flex", justifyContent: "space-evenly" }}
            onClick={this.props.onClick}
          >
            <div className="refresh" id="refresh">
              <IoIcons.IoMdRefresh />
            </div>
            <div>
              <p>Maximum: {Math.round(this.state.max)} W</p>
              <p>Durchschnitt: {Math.round(this.state.mean)} W</p>
              <p>
                Nachgefragte Energie: {Math.round(this.state.sum * 1000) / 1000}{" "}
                kWh
              </p>
            </div>
            <div
              className="refresh"
              style={{
                visibility:
                  "hidden" /* Kennzahlen soll im center des buttons liegen. */,
              }}
            >
              <IoIcons.IoMdRefresh />
            </div>
          </button>
          {this.renderFeedback()}
          {this.renderPhases()}
        </>
      );
    }
  }
}

export function toDate(array) {
  const dateArray = [];
  for (let i = 0; i < array.length; i++) {
    dateArray.push(new Date(array[i]));
  }
  return dateArray;
}

export function evaluate(phases, timestamps, leistung) {
  let durchschnitt_soll = 0;
  let current_phase = {};
  let pass = "true";

  for (const phase of phases) {
    let phaseTimestamps = timestamps.filter(
      (number) => number >= phase.start && number <= phase.end
    );

    if (Date.now() >= phase.start && Date.now() <= phase.end) {
      current_phase = phase;
    }

    const startPhase = timestamps.indexOf(phaseTimestamps[0]);
    const endPhase = timestamps.indexOf(
      phaseTimestamps[phaseTimestamps.length - 1]
    );
    if (phase.geschafft !== "null" && phase.geschafft !== null) {
      pass = phase.geschafft;
      break;
    }
    if (phase.mode === "max") {
      const found = leistung
        .slice(startPhase, endPhase)
        .find((element) => element > phase.limit);
      if (!!found) {
        pass = "false";
        break;
      }
    } else if (phase.mode === "mean") {
      const phaseAverage =
        leistung
          .slice(startPhase, endPhase)
          .reduce((prev, curr) => prev + curr, 0) /
        leistung.slice(startPhase, endPhase).length;
      console.log(phaseAverage)
      if (Date.now() >= phase.start && Date.now() <= phase.end) {
        const sekunden_phase = Math.floor((phase.end - phase.start) / 1000);
        const verbleibende_messungen = Math.floor(
          (phase.end - Date.now()) / 1000
        );
        durchschnitt_soll = Math.floor(
          (phase.limit * sekunden_phase -
            phaseAverage * (endPhase - startPhase)) /
          verbleibende_messungen
        );
        if (durchschnitt_soll < 0) {
          pass = 'false'
        }
      } else {
        if (phaseAverage > phase.limit) {
          pass = 'false'
        }
      }
    }
  }
  return { pass, durchschnitt_soll, current_phase };
}

export default Kennzahlen;
