import {React, useState} from "react";
import qr from '../../assets/ESHL_QR.png'
import { Modal } from 'rsuite';

export default function Qr() {

    const [open, setOpen] = useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);

    return(
        <div className='qr'>
            <img
                id='qr'
                src={qr}
                alt='QR-Code konnte nicht geladen werden.'
                onClick={handleOpen}
                style={{height: '60px'}}>
            </img>
            <Modal open={open} onClose={handleClose}>
                <img
                    className='picModal'
                    id='picQrModal'
                    src={qr}
                    alt='QR-Code konnte nicht geladen werden.'>
                </img>
            </Modal>
        </div>
    )
}