import React from "react";
import * as FaIcons from "react-icons/fa";
import * as AiIcons from "react-icons/ai";
import { Link } from "react-router-dom";
import { SidebarData } from "./SidebarData";
import "./Navbar.css";
import { IconContext } from "react-icons";
import Qr from "../QR/Qr";

//Navigationsleiste der App

class Navbar extends React.Component {
  constructor(props) {
    super(props);

    if (props.identity === "admin") {
      this.state = {
        sidebar: false,
        loggedin: false,
        identity: "admin",
      };
    } else {
      this.state = {
        sidebar: false,
        loggedin: false,
        identity: "user",
      };
    }
    this.handleLogout = this.handleLogout.bind(this);
    this.checkLogInStatus = this.checkLogInStatus.bind(this);
    this.showSidebar = this.showSidebar.bind(this);
  }

  /* showSidebar() legt fest, ob die Sidebar ausgeklappt sein soll oder nicht. */

  showSidebar = () => {
    this.setState({ sidebar: !this.state.sidebar });
  };

  /* handleLogout(e) wird vom Logout Button aufgerufen und ruft die logout() Funktion auf */

  handleLogout = (e) => {
    e.preventDefault();
    this.logout();
  };

  componentDidUpdate(props) {
    if (props.identity !== this.state.identity) {
      this.setState({ identity: props.identity });
    }
  }

  /* logout() löscht access und refresh tokens und erzwingt einen reload, damit (jetzt ausgeloggte) User an der Abfrage in App.js scheitern und zurück auf den Login-Screen geworfen werden. */

  logout() {
    const requestOptionsLogout = {
      method: "POST",
      credentials: "include",
    };
    fetch(
      "https://smarthome.iip.kit.edu/api/token/remove",
      requestOptionsLogout
    )
      .then(this.checkLogInStatus)
      .then(() => window.location.assign("https://smarthome.iip.kit.edu/api/"))
      .catch((error) => {
        console.log(error);
      });
  }

  /* Die Navbar ist der einzige Komponent, der auf jeder Seite auftritt, weswegen wir hier bei jedem Seitenwechsel den Login Status prüfen. */

  componentDidMount() {
    this.checkLogInStatus();
  }

  /* checkLogInStatus() prüft, ob das access-token vorhanden ist. */

  checkLogInStatus() {
    if (!document.cookie) {
      this.setState({ loggedin: false });
    }

    const xsrfCookies = document.cookie
      .split(";")
      .map((c) => c.trim())
      .filter((c) => c.startsWith("csrf_access_token="));
    if (xsrfCookies.length !== 0) {
      this.setState({ loggedin: true }, () => {
        console.log("loginstatus: " + this.state.loggedin);
      });
    } else {
      this.setState({ loggedin: false }, () => {
        console.log("loginstatus: " + this.state.loggedin);
      });
    }
  }

  render() {
    if (this.state.loggedin !== true) {
      // Navbar im Login-Screen
      return (
        <>
          <div
            className="navbar"
            style={{ display: "flex", justifyContent: "center" }}
          >
            <div className="ESHL">Energy Smart Home Lab</div>
            <div className="ESHLshort">ESHL</div>
          </div>
        </>
      );
    }

    return (
      <>
        <IconContext.Provider value={{ color: "#fff" }}>
          <div className="navbar">
            <Link to="#" className="menu-bars">
              <FaIcons.FaBars
                className={this.state.sidebar ? "zw" : "ei"}
                onClick={this.showSidebar}
              />
              <AiIcons.AiOutlineClose
                className={this.state.sidebar ? "ei" : "zw"}
                onClick={this.showSidebar}
              />
            </Link>
            <Qr></Qr>
            <div className="ESHL">Energy Smart Home Lab</div>
            <div className="ESHLshort">ESHL</div>
            <button onClick={this.handleLogout} className="Logout">
              Logout
            </button>
          </div>
          <nav className={this.state.sidebar ? "nav-menu active" : "nav-menu"}>
            <ul className="nav-menu-items" onClick={this.showSidebar}>
              <li className="navbar-toggle">
                <Link to="#" className="menu-bars">
                  <AiIcons.AiOutlineClose />
                </Link>
              </li>
              {(this.state.identity === "admin"
                ? SidebarData
                : SidebarData.slice(0, 5)
              ).map((item, index) => {
                return (
                  <li key={index} className={item.cName}>
                    <Link to={item.path}>
                      {item.icon}
                      <span style={{ marginLeft: "5%" }}>{item.title}</span>
                    </Link>
                  </li>
                );
              })}
            </ul>
          </nav>
        </IconContext.Provider>
      </>
    );
  }
}

export default Navbar;
