import React from "react";
import { useEffect, useState } from "react";
import EarthDay from "./EarthDay";
import TrendPlot from "./TrendPlot";
import Rangliste from "./Rangliste";
import BudgetPlotLive from "../Plot/BudgetPlotLive";
import "./Profil.css";
import Errungenschaften from "./Errungenschaften";

export default function Profil() {
  const [wohnphaseName, setWohnphaseName] = useState("Name");
  const [absolvierteAnreize, setAbsolvierteAnreize] = useState(0);
  const [streak, setStreak] = useState(0);
  const [WohnphaseStart, setWohnphaseStart] = useState("");
  const [WohnphaseEnd, setWohnphaseEnd] = useState("");
  const [punkte, setPunkte] = useState(0);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    fetch(`https://smarthome.iip.kit.edu/api/api/getWohnphase/${Date.now()}/`, {
      method: "GET",
      credentials: "include",
    })
      .then((response) => {
        setLoading(true);
        if (response.ok) {
          return response.json();
        } else {
          return Promise.reject(response.status);
        }
      })
      .then((data) => {
        if (data.length === 1) {
          setWohnphaseName(data[0].name);
          setAbsolvierteAnreize(data[0].absolvierteAnreize);
          setWohnphaseStart(data[0].startts);
          setStreak(data[0].longest_streak);
          setWohnphaseEnd(data[0].endts);
          setPunkte(data[0].punkte);
        }
        setLoading(false);
      })
      .catch((error) => console.log(error));
  }, []);

  function getCookie(name) {
    // Funktion, die das vorhanden Sein eines bestimmten Cookies prüft.
    if (!document.cookie) {
      return null;
    }

    const xsrfCookies = document.cookie
      .split(";")
      .map((c) => c.trim())
      .filter((c) => c.startsWith(name + "="));

    if (xsrfCookies.length === 0) {
      return null;
    }

    return decodeURIComponent(xsrfCookies[0].split("=")[1]);
  }

  const handleFile = (event) => {
    const csrfToken = getCookie("csrf_access_token");
    const formData = new FormData();
    formData.append("fileupload", event.target.files[0]);

    fetch(
      `https://smarthome.iip.kit.edu/api/api/saveProfilBild/${Date.now()}/`,
      {
        method: "POST",
        credentials: "include",
        headers: {
          "X-CSRF-TOKEN": csrfToken,
        },
        body: formData,
        dataType: "jsonp",
      }
    )
      .then((response) => {
        if (response.ok) {
          return response.blob();
        }
      })
      .then((data) => {
        document.getElementById("profilePicture").src =
          URL.createObjectURL(data);
      })
      .catch((error) => console.log(error));
  };

  return (
    <div
      style={{
        margin: "2% 0",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        fontSize: "150%",
      }}
    >
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          width: "100%",
          textAlign: "center",
        }}
      >
        {!!WohnphaseStart && !!WohnphaseEnd ? (
          <div
            style={{ display: "flex", flexDirection: "column", width: "100%" }}
          >
            <p>{wohnphaseName}</p>
            <p style={{ margin: "0" }}>
              {new Date(WohnphaseStart).toLocaleDateString()} bis{" "}
              {new Date(WohnphaseEnd).toLocaleDateString()}
            </p>
          </div>
        ) : (
          <p>{wohnphaseName}</p>
        )}
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            width: "100%",
            flexWrap: "wrap",
            justifyContent: "center",
          }}
        >
          <form className="form">
            <label
              htmlFor="photo-upload"
              className="custom-file-upload fas label"
            >
              <div className="img-wrap img-upload">
                <img
                  htmlFor="photo-upload"
                  className="img"
                  id="profilePicture"
                  src={`https://smarthome.iip.kit.edu/api/api/getProfilBild/${Date.now()}/?ts=${Date.now()}`}
                  alt=""
                />
              </div>
              <input
                id="photo-upload"
                accept="image/png"
                type="file"
                className="input"
                onChange={(event) => {
                  handleFile(event);
                }}
              />
            </label>
          </form>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-evenly",
              margin: "2% 0",
              flexGrow: "1",
            }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "space-evenly",
              }}
            >
              <p style={{ textAlign: "center" }}>
                absolvierte <br /> Anreize
              </p>
              <p>{absolvierteAnreize}</p>
            </div>
            <span
              style={{ width: "2px", backgroundColor: "rgb(43, 59, 67)" }}
            ></span>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "space-evenly",
              }}
            >
              <p>Streak</p>
              <p>{streak}</p>
            </div>
            <span
              style={{ width: "2px", backgroundColor: "rgb(43, 59, 67)" }}
            ></span>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "space-evenly",
              }}
            >
              <p>Punkte</p>
              <p>{punkte}</p>
            </div>
          </div>
        </div>
      </div>
      <Rangliste></Rangliste>
      <Errungenschaften></Errungenschaften>
      {loading ? <></> : <EarthDay start={WohnphaseStart}></EarthDay>}
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          width: "100%",
          flexWrap: "wrap",
          justifyContent: "center",
        }}
      >
        <TrendPlot></TrendPlot>
        {/* <TrendPlot></TrendPlot> */}
        <div className="parent">
          <BudgetPlotLive
            date={new Date()}
          />
        </div>
      </div>
    </div >
  );
}
