import React from "react";
import { Document, Page } from "react-pdf";

// Die PDF wird mit jeder Seite dargestellt.

export default class AllPages extends React.Component {

  constructor(props) {
    super(props);
    this.state={
      numPages: 3,
      chachedWidth: window.outerWidth
    }
  }

  handleResize = (e) => {
    if (this.state.chachedWidth !== window.outerWidth) {
      window.location.reload(false)   //deprecated; Der Browser wird zu einem reload gezwungen, da sich die PDF ansonsten nicht immer wieder an die Größe des viewports anpasst.
    }
    this.setState({chachedWidth: window.outerWidth})
  }


  componentDidMount() {
    window.addEventListener("resize", this.handleResize); // Immer wenn die Größe des viewports geändert wird, wird handleResize() aufgerufen
  }

  render() {
    return (
        <Document
          file={this.props.pdf}
          options={{ workerSrc: "/pdf.worker.js" }}  //warum auch immer musste pdf.worker.js in den public Ordner der App und konnte nicht in den node_modules bleiben.
        >
          {Array.from(new Array(this.state.numPages), (el, index) => (
            <Page width={(window.innerWidth>1080)? window.innerWidth*0.8 : window.innerWidth} key={`page_${index + 1}`} pageNumber={index + 1} />  // Die PDF hat entweder 100% oder 80% der Weite des viewports.
          ))}
        </Document>
      );
  }
}