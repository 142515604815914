import React from 'react';
import './Zeitspanne.css';
import LinePlot from '../Plot/LinePlot';
import BarPlot from '../Plot/BarPlot';
import { DateRangePicker } from 'rsuite';

/*Tag.js und Zeitspanne.js sind leider misslungene Komponenten. Durch Radio-Buttons werden verschiedene Anreize ausgewählt, jedoch findet dies im Code sehr umständlich 
mit if/else Anweisungen statt. Es funktioniert, uns fehlt leider die Zeit das ganze nochmal gescheit zu implementieren.*/


class Zeitspanne extends React.Component {

  constructor(props){
    super(props);
    this.state = { 
      startdate: new Date(new Date().setHours(0,0,0,0)),
      enddate: new Date(),
      startts: new Date(new Date().setHours(0,0,0,0)) * 1,
      endts: new Date() * 1,
      spalte: 'Leistung_Gesamt',
      anzeige: 'min',
    }
    this.handleChange = this.handleChange.bind(this);
    this.onChangeValue = this.onChangeValue.bind(this);
    this.click = this.click.bind(this);
  }

    /* click() setzt bei Drücken des "Zeitspanne abfragen" Buttons den State auf den ausgewählten Anfangs- bzw. Endtimestamp.
       Hier werden States auf States gesetzt, um redraws während der Nutzereingabe zu vermeiden.
    */

    click() {
        this.setState({startts: this.state.startdate * 1,
                        endts: this.state.enddate * 1})
    }

    /* handleChange(e) reagiert auf die Auswahl eines bestimmten Anschlusses des ESHL in <select>. */
  
    handleChange(e) {
      this.setState({
        spalte: e.target.value
      })
    }

    /* onChangeValue(event) reagiert auf die Auswahl der verschiedenen Anzeigen durch die oben angesprochenen Radio Buttons. */

    onChangeValue(event) {
      this.setState({
        anzeige: event.target.value
      })
    }
    
    /* render() stellt abhängig vom State 4 verschieden Anzeigen dar.*/
      
      render() {
        if(this.state.anzeige === 'min'){           //Anzeige: Plot(min)
        return (
            <div className = "parent">
            <div className="ZeitspanneStyle">
              <DateRangePicker
                cleanable={false}
                format={"dd.MM.yyyy"} 
                value={[this.state.startdate, this.state.enddate]}
                display="default"
                onOk={ (value) => this.setState({startdate: value[0] , enddate: new Date(value[1].setHours(23,59,59,999))}) }
                style={{marginBottom: '2%'}}
              />
              <button className='labelClass' onClick={this.click}>Zeitspanne abfragen</button>
            </div>
            <form className='tagForm' onChange={this.onChangeValue}>
              <input id="bb1" type='radio' name='auswahl' value='sec'/><label className='labelClass' for="bb1"> Plot (sek.) </label>
              <input id="bb2" type='radio' name='auswahl' value='min' defaultChecked/><label className='labelClass' for="bb2"> Plot (min.) </label>
              <input id="bb3" type='radio' name='auswahl' value='bar'/><label className='labelClass' for="bb3"> Balkendiagramm </label>
              </form>
                 <select value={this.state.spalte} onChange={this.handleChange}>
                      <option value='Leistung_Gesamt'>Leistung Gesamt</option>
                      <option value='2fach_Dose_Eingang'>2fach_Dose_Eingang</option>
                      <option value='2fach_Dose_Herd_rechts'>2fach_Dose_Herd_rechts</option>
                      <option value='2fach_Dose_Kuechenfenster_links'>2fach_Dose_Kuechenfenster_links</option>
                      <option value='2fach_Dose_Kuechenfenster_rechts'>2fach_Dose_Kuechenfenster_rechts</option>
                      <option value='2fach_Dose_Tuer_Schlafen1'>2fach_Dose_Tuer_Schlafen1</option>
                      <option value='2fach_Dose_Tuer_Schlafen2'>2fach_Dose_Tuer_Schlafen2</option>
                      <option value='3fach_Dose_Bad'>3fach_Dose_Bad</option>
                      <option value='3fach_Dose_Kueche_links'>3fach_Dose_Kueche_links</option>
                      <option value='3fach_Dose_Kueche_rechts'>3fach_Dose_Kueche_rechts</option>
                      <option value='4fach_Dose_TV_1'>4fach_Dose_TV_1</option>
                      <option value='4fach_Dose_TV_2'>4fach_Dose_TV_2</option>
                      <option value='5fach_Dose_Schlafen1_1'>5fach_Dose_Schlafen1_1</option>
                      <option value='5fach_Dose_Schlafen1_2'>5fach_Dose_Schlafen1_2</option>
                      <option value='5fach_Dose_Schlafen2_1'>5fach_Dose_Schlafen2_1</option>
                      <option value='5fach_Dose_Schlafen2_2'>5fach_Dose_Schlafen2_2</option>
                      <option value='Backofen'>Backofen</option>
                      <option value='Dunstabzugshaube'>Dunstabzugshaube</option>
                      <option value='Gefrierschrank'>Gefrierschrank</option>
                      <option value='Kaffeemaschine'>Kaffeemaschine</option>
                      <option value='Klimaanlage'>Klimaanlage</option>
                      <option value='Kochfeld1'>Kochfeld1</option>
                      <option value='Kochfeld2'>Kochfeld2</option>
                      <option value='Kochfeld3'>Kochfeld3</option>
                      <option value='Kuehlschrank'>Kuehlschrank</option>
                      {/* <option value='LEER1'>LEER1</option>
                      <option value='LEER2'>LEER2</option>
                      <option value='LEER3'>LEER3</option>
                      <option value='LEER4'>LEER4</option>
                      <option value='LEER5'>LEER5</option>
                      <option value='LEER6'>LEER6</option> */}
                      <option value='Licht_Bad'>Licht_Bad</option>
                      <option value='Licht_Hauptraum'>Licht_Hauptraum</option>
                      <option value='Licht_Schlafen1'>Licht_Schlafen1</option>
                      <option value='Licht_Schlafen2'>Licht_Schlafen2</option>
                      <option value='Spuelmaschine'>Spuelmaschine</option>
                      <option value='Trockner'>Trockner</option>
                      <option value='Tuer_zu_Schlafen1'>Tuer_zu_Schlafen1</option>
                      <option value='Tuer_zu_Schlafen2'>Tuer_zu_Schlafen2</option>
                      <option value='Waschmaschine'>Waschmaschine</option>
                      <option value='PVGes'>PV-Anlagen</option>
                  </select>
                  
            <div>
                {/* endtime wird auf den eingegebenen Endtimestamp, oder auf den aktuellen Timestamp gesetzt, falls ein Endtimestamp in der Zukunft abgefragt wird.*/}
                <LinePlot spalte= {this.state.spalte} starttime = {this.state.startts} endtime={this.state.endts <= Date.now() * 1 ? this.state.endts : Date.now() * 1} min = 'true'/>
            </div>
            </div>
        );
        }
        else if(this.state.anzeige === 'sec'){
            return (
                <div className = "parent">
                  <div className="ZeitspanneStyle">
                    <DateRangePicker
                      cleanable={false}
                      format={"dd.MM.yyyy"} 
                      value={[this.state.startdate, this.state.enddate]}
                      onOk={ (value) => this.setState({startdate: value[0] , enddate: new Date(value[1].setHours(23,59,59,999))}) }
                      style={{marginBottom: '2%'}}
                    />
                    <button className='labelClass' onClick={this.click}>Zeitspanne abfragen</button>
                  </div>
                <form className='tagForm' onChange={this.onChangeValue}>
                  <input id="bb5" type='radio' name='auswahl' value='sec' defaultChecked/><label className='labelClass' for="bb5"> Plot (sek.) </label>
                  <input id="bb6" type='radio' name='auswahl' value='min'/><label className='labelClass' for="bb6">Plot (min.) </label>
                  <input id="bb7" type='radio' name='auswahl' value='bar'/><label className='labelClass' for="bb7"> Balkendiagramm </label>
                </form>
                      <select value={this.state.spalte} onChange={this.handleChange}>
                        <option value='Leistung_Gesamt'>Leistung Gesamt</option>
                        <option value='2fach_Dose_Eingang'>2fach_Dose_Eingang</option>
                        <option value='2fach_Dose_Herd_rechts'>2fach_Dose_Herd_rechts</option>
                        <option value='2fach_Dose_Kuechenfenster_links'>2fach_Dose_Kuechenfenster_links</option>
                        <option value='2fach_Dose_Kuechenfenster_rechts'>2fach_Dose_Kuechenfenster_rechts</option>
                        <option value='2fach_Dose_Tuer_Schlafen1'>2fach_Dose_Tuer_Schlafen1</option>
                        <option value='2fach_Dose_Tuer_Schlafen2'>2fach_Dose_Tuer_Schlafen2</option>
                        <option value='3fach_Dose_Bad'>3fach_Dose_Bad</option>
                        <option value='3fach_Dose_Kueche_links'>3fach_Dose_Kueche_links</option>
                        <option value='3fach_Dose_Kueche_rechts'>3fach_Dose_Kueche_rechts</option>
                        <option value='4fach_Dose_TV_1'>4fach_Dose_TV_1</option>
                        <option value='4fach_Dose_TV_2'>4fach_Dose_TV_2</option>
                        <option value='5fach_Dose_Schlafen1_1'>5fach_Dose_Schlafen1_1</option>
                        <option value='5fach_Dose_Schlafen1_2'>5fach_Dose_Schlafen1_2</option>
                        <option value='5fach_Dose_Schlafen2_1'>5fach_Dose_Schlafen2_1</option>
                        <option value='5fach_Dose_Schlafen2_2'>5fach_Dose_Schlafen2_2</option>
                        <option value='Backofen'>Backofen</option>
                        <option value='Dunstabzugshaube'>Dunstabzugshaube</option>
                        <option value='Gefrierschrank'>Gefrierschrank</option>
                        <option value='Kaffeemaschine'>Kaffeemaschine</option>
                        <option value='Klimaanlage'>Klimaanlage</option>
                        <option value='Kochfeld1'>Kochfeld1</option>
                        <option value='Kochfeld2'>Kochfeld2</option>
                        <option value='Kochfeld3'>Kochfeld3</option>
                        <option value='Kuehlschrank'>Kuehlschrank</option>
                        {/* <option value='LEER1'>LEER1</option>
                        <option value='LEER2'>LEER2</option>
                        <option value='LEER3'>LEER3</option>
                        <option value='LEER4'>LEER4</option>
                        <option value='LEER5'>LEER5</option>
                        <option value='LEER6'>LEER6</option> */}
                        <option value='Licht_Bad'>Licht_Bad</option>
                        <option value='Licht_Hauptraum'>Licht_Hauptraum</option>
                        <option value='Licht_Schlafen1'>Licht_Schlafen1</option>
                        <option value='Licht_Schlafen2'>Licht_Schlafen2</option>
                        <option value='Spuelmaschine'>Spuelmaschine</option>
                        <option value='Trockner'>Trockner</option>
                        <option value='Tuer_zu_Schlafen1'>Tuer_zu_Schlafen1</option>
                        <option value='Tuer_zu_Schlafen2'>Tuer_zu_Schlafen2</option>
                        <option value='Waschmaschine'>Waschmaschine</option>
                        <option value='PVGes'>PV-Anlagen</option>
                      </select>
                <div>
                    {/* endtime wird auf den eingegebenen Endtimestamp, oder auf den aktuellen Timestamp gesetzt, falls ein Endtimestamp in der Zukunft abgefragt wird.*/}
                    <LinePlot spalte= {this.state.spalte} starttime = {this.state.startts} endtime={this.state.endts <= Date.now() * 1 ? this.state.endts : Date.now() * 1} min = 'false'/>
                </div>
                </div>
            );
          }
          else if(this.state.anzeige === 'bar'){
            return(
              <div className = "parent">
              <div className="ZeitspanneStyle">
                <DateRangePicker
                  cleanable={false}
                  format={"dd.MM.yyyy"}
                  value={[this.state.startdate, this.state.enddate]}
                  onOk={ (value) => this.setState({startdate: value[0] , enddate: new Date(value[1].setHours(23,59,59,999))}) }
                  style={{marginBottom: '2%'}}
                />
                <button className='labelClass' onClick={this.click}>Zeitspanne abfragen</button>
              </div>
              <form className='tagForm' onChange={this.onChangeValue}>
                <input id="bb9" type='radio' name='auswahl' value='sec'/><label className='labelClass' for="bb9"> Plot (sek.) </label>
                <input id="bb10" type='radio' name='auswahl' value='min'/><label className='labelClass' for="bb10"> Plot (min.) </label>
                <input id="bb11" type='radio' name='auswahl' value='bar'  defaultChecked/><label className='labelClass' for="bb11"> Balkendiagramm </label>
              </form>
              <div>
                  <BarPlot starttime = {this.state.startts} endtime={this.state.endts}/>
              </div>
              </div>
            )
          }
      }
    }        



export default Zeitspanne